<template>
  <component :is="itemData === undefined ? 'div' : 'b-card'">
    <!-- Alert: No item found -->
    <b-alert variant="danger" :show="itemData === undefined">
      <h4 class="alert-heading">
        Erreur de récupération des données de produit
      </h4>
      <div class="alert-body">
        Pas de produit trouvé sur cet identifiant. Vérifiez
        <b-link class="alert-link" :to="{ name: 'apps-items-list' }">
          la liste des produits
        </b-link>
        pour d'autres produits.
      </div>
    </b-alert>

    <b-tabs v-if="itemData" pills>
      <!-- Tab: Général -->
      <b-tab active>
        <template #title>
          <feather-icon icon="UserIcon" size="16" class="mr-0 mr-sm-50" />
          <span class="d-none d-sm-inline">Création Produit</span>
        </template>
        <handle-item-tab-general
          class="mt-2 pt-75"
          :item-data.sync="itemData"
          @data-handled="$router.push({ name: 'apps-items-list' })"
        />
      </b-tab>
    </b-tabs>
  </component>
</template>

<script>
/* eslint-disable */
import { BTab, BTabs, BCard, BAlert, BLink } from "bootstrap-vue";
import { ref, onMounted } from "@vue/composition-api";
import router from "@/router";
import store from "@/store";
import HandleItemTabGeneral from "./HandleItemTabGeneral.vue";

export default {
  name: "HandleItem",
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,

    HandleItemTabGeneral,
  },
  setup() {
    const blankItemData = {
      name: "",
      description: "",
      is_depreciable: false,
      brand: null,
      measure: null,
      category: [],
    };

    const itemData = ref(JSON.parse(JSON.stringify(blankItemData)));
    const fetchItem = () => {
      store
        .dispatch("item/fetchItem", {
          id: router.currentRoute.params.id,
        })
        .then((item) => {
          itemData.value = item;
        })
        .catch((error) => {
          if (error.response.status === 404) {
            itemData.value = undefined;
          }
        });
    };

    onMounted(() => {
      if (router.currentRoute.params.id) {
        fetchItem();
      }
    });

    return {
      itemData,
    };
  },
};
</script>
