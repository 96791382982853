<template>
  <validation-observer
    ref="refFormObserver"
    #default="{ handleSubmit, invalid }"
  >
    <!-- Status Info: Input Fields -->
    <b-form
      @submit.prevent="handleSubmit(submitData)"
      @reset.prevent="resetForm"
    >
      <b-row>
        <!-- Field: label -->
        <b-col cols="12" md="4">
          <validation-provider
            #default="validationContext"
            name="label"
            rules="required"
          >
            <b-form-group label="Libellé" label-for="label">
              <b-form-input id="label" v-model="item.name" type="text" />
              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>

        <!-- Field: depreciation -->
        <b-col class="d-flex align-items-center" cols="12" md="4">
          <validation-provider
            #default="validationContext"
            name="is_depreciable"
            rules="required"
          >
            <b-form-group
              label-for="is_depreciable"
              :state="getValidationState(validationContext)"
            >
              <b-form-checkbox
                v-model="item.is_depreciable"
                class="custom-control-warning"
              >
                <span class="text-bold">Est Amortissable ?</span>
              </b-form-checkbox>
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
      </b-row>

      <b-row>
        <!-- Field: measure -->
        <b-col cols="12" md="4">
          <validation-provider
            #default="validationContext"
            name="measure"
            rules="required"
          >
            <b-form-group
              label="Mesure"
              label-for="measure"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="item.measure"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="measuresList"
                :reduce="(option) => option.uid"
                label="name"
                id="measure"
                :clearable="false"
                placeholder="Choisir la mesure"
              >
                <template v-slot:no-options="{ search, searching }">
                  <template v-if="searching">
                    Pas de résultat pour <em>{{ search }}</em
                    >.
                  </template>
                  <em v-else style="opacity: 0.5">Recherche une mesure...</em>
                </template>
              </v-select>
              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>

        <!-- Field: brand -->
        <b-col cols="12" md="4">
          <validation-provider
            #default="validationContext"
            name="brand"
            rules="required"
          >
            <b-form-group
              label="Marque"
              label-for="brand"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="item.brand"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="brandsList"
                :reduce="(option) => option.uid"
                label="name"
                id="brand"
                :clearable="false"
                placeholder="Choisir la marque"
              >
                <template v-slot:no-options="{ search, searching }">
                  <template v-if="searching">
                    Pas de résultat pour <em>{{ search }}</em
                    >.
                  </template>
                  <em v-else style="opacity: 0.5">Recherche une marque...</em>
                </template>
              </v-select>
              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
      </b-row>

      <b-row>
        <!-- Field: category -->
        <b-col cols="12" md="4">
          <validation-provider
            #default="validationContext"
            name="categories"
            rules="required"
          >
            <b-form-group
              label="Catégories"
              label-for="categories"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="item.categories"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="categoriesList"
                :reduce="(option) => option.uid"
                label="name"
                id="categories"
                multiple
                placeholder="Choisir la catégorie"
              >
                <template v-slot:no-options="{ search, searching }">
                  <template v-if="searching">
                    Pas de résultat pour <em>{{ search }}</em
                    >.
                  </template>
                  <em v-else style="opacity: 0.5"
                    >Recherche une catégorie...</em
                  >
                </template>
              </v-select>
              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>

        <!-- Field: Description -->
        <b-col cols="12" md="4">
          <!-- details -->
          <validation-provider #default="validationContext" name="description">
            <b-form-group label="Description" label-for="description">
              <b-form-textarea v-model="item.description" id="description" />
              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
      </b-row>

      <!-- Action Buttons -->
      <b-button
        variant="primary"
        class="mb-1 mb-sm-0 mr-0 mr-sm-1"
        :block="$store.getters['app/currentBreakPoint'] === 'xs'"
        type="submit"
        :disabled="isLoading || invalid"
      >
        <b-spinner v-if="isLoading" small></b-spinner>
        {{ item.uid ? "Modifier" : "Sauvegarder" }}
      </b-button>
      <b-button
        variant="outline-secondary"
        type="reset"
        :block="$store.getters['app/currentBreakPoint'] === 'xs'"
      >
        Annuler
      </b-button>
    </b-form>
  </validation-observer>
</template>

<script>
import {
  BButton,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BFormInvalidFeedback,
  BFormTextarea,
  BSpinner,
  BFormCheckbox,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";

import { computed, onMounted, ref, watch } from "@vue/composition-api";
import { heightFade } from "@core/directives/animations";
import formValidation from "@core/comp-functions/forms/form-validation";
import store from "@/store";
import { required } from "@validations";

import vSelect from "vue-select";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { useToast } from "vue-toastification/composition";
import router from "@/router";

export default {
  name: "HandleItemTabGeneral",
  components: {
    BFormCheckbox,
    BSpinner,
    BFormTextarea,
    BFormInvalidFeedback,
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    vSelect,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    itemData: {
      type: Object,
      required: true,
    },
  },
  emit: ["data-handled"],
  directives: {
    "height-fade": heightFade,
  },
  setup(props, { emit }) {
    const toast = useToast();

    const isLoading = ref(false);
    const item = ref(JSON.parse(JSON.stringify(props.itemData)));

    const resetCategoryLocal = () => {
      item.value = ref(JSON.parse(JSON.stringify(props.itemData)));
    };

    const showToast = (messages, icon, variant) => {
      toast({
        component: ToastificationContent,
        props: {
          title: messages,
          icon: icon,
          variant: variant,
        },
      });
    };

    const getError = computed(() => {
      return store.getters["item/errors"];
    });

    const measuresList = computed(() => {
      return availableProperties("measures");
    });

    const categoriesList = computed(() => {
      return availableProperties("categories");
    });

    const brandsList = computed(() => {
      return availableProperties("brands");
    });

    const availableProperties = (type) => {
      const properties = store.getters["item/allProperties"];
      return properties.hasOwnProperty(type) ? properties[type] : [];
    };

    const resetError = () => store.commit("itemCategory/SET_ERRORS", null);

    const { refFormObserver, getValidationState, resetForm, clearForm } =
      formValidation(resetCategoryLocal);

    const handleSuccess = (message) => {
      emit("data-handled");
      showToast(message, "CheckIcon", "success");
    };

    const handleErrors = (errors) => {
      for (const [field, message] of Object.entries(errors)) {
        refFormObserver.value.setErrors({ [field]: message[0] });
      }
    };

    watch(getError, (val) => {
      if (val) {
        showToast(val.message, "AlertTriangleIcon", "danger");
        handleErrors(val.error);
      }
    });

    onMounted(() => {
      store.dispatch("item/fetchItemProperties", {
        props: ["measures", "categories", "brands"],
      });
    });

    const submitData = async () => {
      isLoading.value = true;
      resetError();

      try {
        if (item.uid) {
          const { message } = await store.dispatch("item/updateItem", {
            data: item.value,
          });
          handleSuccess(message);
        } else {
          const { message } = await store.dispatch("item/addItem", {
            data: item.value,
          });
          handleSuccess(message);
        }
        clearForm();
      } catch (e) {
        //
      } finally {
        resetError();
        isLoading.value = false;
      }
    };

    return {
      required,
      isLoading,
      item,
      measuresList,
      categoriesList,
      brandsList,
      submitData,
      refFormObserver,

      resetForm,
      getValidationState,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
